import * as React from "react";
import { Link } from "gatsby";
import Layout from "../../components/layout";
import Seo from "../../components/seo";
import "./css/custom-development.css";
import "../../components/consultingCall/ConsultingCallButton";
import {
  Button,
  CardList,
  SimpleBanner,
  BannerTitle,
  BannerContent,
  FeatureList,
  FeatureListItem,
  FactSet,
  Fact,
  Icon,
} from "@launchacademy/voyager";
import {
  appIdeaContent,
  workWithUsContent,
  workWithUsColumns,
} from "../../pageData/consultingPageData";

import buildImage from "../../services/buildImage";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { graphql } from "gatsby";

import RoundedCard from "../../components/roundedCard";

import growthAnimation from "../../animationData/161-growth-outline.json";
import savingsAnimation from "../../animationData/453-savings-pig-outline.json";
import processAnimation from "../../animationData/974-process-flow-game-plan-outline.json";
import formulaAnimation from "../../animationData/1243-chemistry-outline-edited.json";
import { Lottie } from "../../components/lottie/Lottie";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import ConsultingCallButton from "../../components/consultingCall/ConsultingCallButton";

const ConsultingPage = ({ data, location }) => {
  const appIdeaContentWithImages = appIdeaContent.map((content) => {
    const imageDataForContent = data[content.id];
    return { ...content, Image: buildImage(imageDataForContent) };
  });

  const workWithUsContentWithImages = workWithUsContent.map((content) => {
    const imageDataForContent = data[content.id];
    return { ...content, Image: buildImage(imageDataForContent) };
  });

  const laptopHeroImageStyle = { "object-fit": "scale-down" };
  const consultingFeatureIconsStyle = { marginBottom: "4rem" };

  const languageListItems = [
    {
      imageData: data.rails,
      name: "Rails",
    },
    {
      imageData: data.react,
      name: "React",
    },
    {
      imageData: data.node,
      name: "Node",
    },
    {
      imageData: data.ios,
      name: "iOS",
    },
    {
      imageData: data.android,
      name: "Android",
    },
  ].map((item) => {
    return (
      <li key={item.name}>
        <GatsbyImage image={getImage(item.imageData)} alt={`${item.name} Logo`} />
      </li>
    );
  });

  const deploymentAndDevOpsLogos = [
    {
      imageData: data.aws,
      name: "AWS",
    },
    {
      imageData: data.googleCloudPlatform,
      name: "Google Cloud Platform",
    },
    {
      imageData: data.docker,
      name: "Docker",
    },
    {
      imageData: data.heroku,
      name: "Heroku",
    },
  ].map((item) => {
    return (
      <li key={item.name}>
        <GatsbyImage image={getImage(item.imageData)} alt={`${item.name} Logo`} />
      </li>
    );
  });

  return (
    <Layout
      primaryCta={
        <ConsultingCallButton
          size="md"
          location={location}
          text="Schedule Call"
          icon={faChevronRight}
        />
      }
    >
      <Seo title="Team" />
      <section className="hero">
        <div className="hero__image-column">
          <div className="hero__image-container">
            <GatsbyImage
              image={getImage(data.homePageLaptop)}
              className="hero__image hero__image_square"
              alt="students and staff posing for a picture"
              imgStyle={laptopHeroImageStyle}
            />
          </div>
        </div>
        <div className="hero__supporting-content">
          <h3 className="hero__image_subtitle">Skip the headache and micromanagement</h3>
          <h1 className="hero__image_title">Get your custom software built</h1>
          <div className="hero__button-container">
            <ConsultingCallButton
              location={location}
              className="hero__button"
              size="lg"
              icon={faChevronRight}
            />
          </div>
        </div>
      </section>
      <section className="consulting-benefits">
        <h2 className="mb-10">Take your app idea from concept to LIVE.</h2>
        <div className="">
          <ul className="consulting-benefits__list">
            <li>
              <Lottie data={growthAnimation} animateInView={true} />
              <h3 className="consulting-benefits__headline">Scales with your organization</h3>
            </li>
            <li>
              <Lottie data={savingsAnimation} />
              <h3 className="consulting-benefits__headline">On budget and on time</h3>
            </li>
            <li>
              <Lottie data={processAnimation} />
              <h3 className="consulting-benefits__headline">Transparent process</h3>
            </li>
          </ul>
        </div>
      </section>
      <SimpleBanner type="prose" BackgroundImage={buildImage(data.collaboratingBackground)}>
        <BannerTitle>Your idea deserves to connect with great software.</BannerTitle>
        <BannerContent>
          <p id="banner__container-text">
            Choosing the wrong development partner can leave you feeling stressed out and
            frustrated. Even worse, it could leave you with a lot invested with nothing to show for
            it.
            <br />
            <br /> We think an unrealized vision is tragic, and that bringing your idea to life
            should be exhilarating, not frustrating. Your project should have its chance to make
            lives better. We can help you to get there, without all of the needless micromanagement
            and painful debate over what's a bug vs. what's a feature.
          </p>
        </BannerContent>
        <ConsultingCallButton
          location={location}
          className="mt-5 lg:mt-10"
          placement="photo"
          secondary
          size="lg"
        />
      </SimpleBanner>
      <div className="consulting-feature-list">
        <FeatureList
          title="Make your software product a reality with the right development team."
          prefixContent={
            <>
              <GatsbyImage
                image={getImage(data.morningMentorGroup)}
                className="consulting-feature-list__image_primary"
                alt="Planning a sprint"
              />
              <GatsbyImage
                image={getImage(data.threeStudentsTalking)}
                style={{ opacity: 0.3 }}
                className=" consulting-feature-list__image_secondary"
                alt="Developer walking through code"
              />
            </>
          }
        >
          <FeatureListItem
            title="A Plan To Take You Where You Want to Go"
            description="Every product idea requires a plan to get it to production. Connect your concept with a roadmap on how to bring it to life."
            iconName="info-circle"
          />
          <FeatureListItem
            title="Understand What's Happening With Your Product"
            description="A transparent and iterative process allows your product to evolve with your business. Enjoy adapting to the needs of your stakeholders instead of haggling over requirements."
            iconName="info-circle"
          />
          <FeatureListItem
            title="Get Live!"
            description="Get your product in the hands of those that need it. Let's start making lives better together."
            iconName="info-circle"
          />
        </FeatureList>
      </div>
      <section className="fact-set-consulting text-center">
        <h2 className="section-title mb-4">You have enough on your plate.</h2>
        <p>
          Micromanaging your development partner shouldn't be another item on your todo list. We're
          in the business of launching:
        </p>
        <FactSet>
          <Fact value="30+" label="Applications launched" className="md:place-self-end" />
          <Fact
            value="1000+"
            label="Developers trained at our school"
            className="md:place-self-start"
          />
        </FactSet>
      </section>
      <section className="step-list rounded-card-list">
        <section className="step-list__container">
          <Lottie data={formulaAnimation} className="step-list__formula-icon" />
          <h2 className="step-list__header">Your Product Launch Formula</h2>
          <div className="step-list__card-list">
            <RoundedCard
              overHeader="STEP 1"
              title="Define"
              descriptionText="Establish what you want to build. Go from just an idea to a plan with a budget, timeline, and architecture."
              Image={buildImage(data.defineImage)}
            />
            <RoundedCard
              overHeader="STEP 2"
              title="Develop"
              descriptionText="Take your idea and start making it real.
        Build a product that drives revenue or gains efficiency in your organization."
              Image={buildImage(data.developImage)}
            />
            <RoundedCard
              overHeader="STEP 3"
              title="Deploy"
              descriptionText="Put what you've built in the hands of your stakeholders. Let's learn and refine the product together."
              Image={buildImage(data.deployImage)}
            />
          </div>
          {/* <div className="step-list__button"> */}
          <ConsultingCallButton
            icon={faChevronRight}
            location={location}
            text="Schedule Call"
            className="hero_button mt-5 lg:ml-64 lg:mt-10"
            size="lg"
          />
        </section>
        {/* </div> */}
      </section>
      <section
        className="program-features card-list-container mt-20 work-features"
        style={consultingFeatureIconsStyle}
      >
        <h2 className="mb-10">Work with us</h2>
        <CardList
          content={workWithUsContentWithImages}
          numberOfColumns={workWithUsColumns}
          className="grid-flow-row auto-cols-max place-content-center "
          learnMoreToggle
          learnMoreContent={
            <ConsultingCallButton
              buttonMode="link"
              className="card__learn-more-link"
              text="Learn More"
              location={location}
            />
          }
        />
      </section>
      <section className="consulting-technologies consulting-technologies_frameworks">
        <h2 className="section-title">Programming Languages & Frameworks</h2>
        <ul className="consulting-technologies__logo-list">{languageListItems}</ul>
      </section>
      <section className="consulting-technologies consulting-technologies_devops">
        <h2 className="section-title">Deployment & DevOps</h2>
        <ul className="consulting-technologies__logo-list">{deploymentAndDevOpsLogos}</ul>
      </section>
      {false && (
        <section className="launch-guide-banner">
          <SimpleBanner type="prose" BackgroundImage={buildImage(data.launchHardcoverBook)}>
            <BannerTitle>Download our Launch Guide</BannerTitle>
            <BannerContent>
              <p id="launch-guide-banner__content">Put our framework to work for your product.</p>
            </BannerContent>
            <Button
              to="#"
              text="Download"
              className="mt-5 lg:ml-0 lg:mt-10"
              placement="photo"
              secondary
              size="lg"
            />
          </SimpleBanner>
        </section>
      )}
    </Layout>
  );
};

export default ConsultingPage;

export const pageQuery = graphql`
  query {
    homePageLaptop: file(relativePath: { regex: "/consulting-page/homePageLaptop.png/" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, breakpoints: [210, 630])
      }
    }
    scalesWithOrganization: file(
      relativePath: { regex: "/consulting-page/scalesWithOrganization.jpeg/" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, breakpoints: [210, 630])
      }
    }
    onBudget: file(relativePath: { regex: "/consulting-page/onBudget.jpeg/" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, breakpoints: [210, 630])
      }
    }
    transparentProcess: file(relativePath: { regex: "/consulting-page/transparentProcess.jpeg/" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, breakpoints: [210, 630])
      }
    }
    chemistryBeaker: file(relativePath: { regex: "/consulting-page/chemistryBeaker.png/" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, breakpoints: [210, 630])
      }
    }
    collaboratingBackground: file(
      relativePath: { regex: "/consulting-page/collaboratingBackground.jpg/" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, breakpoints: [210, 630])
      }
    }
    threeStudentsTalking: file(
      relativePath: { regex: "/consulting-page/threeStudentsTalking.jpg/" }
    ) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED
          width: 230
          height: 250
          transformOptions: { grayscale: true }
        )
      }
    }
    morningMentorGroup: file(relativePath: { regex: "/consulting-page/morningMentorGroup.jpg/" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, height: 450, width: 380)
      }
    }
    defineImage: file(relativePath: { regex: "/consulting-page/defineImage.jpg/" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, breakpoints: [210, 630])
      }
    }
    developImage: file(relativePath: { regex: "/consulting-page/developImage.jpg/" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, breakpoints: [210, 630])
      }
    }
    deployImage: file(relativePath: { regex: "/consulting-page/deployImage.jpg/" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, breakpoints: [210, 630])
      }
    }
    flightPlan: file(relativePath: { regex: "/consulting-page/flightPlan.png/" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, breakpoints: [210, 630])
      }
    }
    systemCheck: file(relativePath: { regex: "/consulting-page/systemsCheck.png/" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, breakpoints: [210, 630])
      }
    }
    testLaunch: file(relativePath: { regex: "/consulting-page/testLaunch.png/" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, breakpoints: [210, 630])
      }
    }
    launchHardcoverBook: file(
      relativePath: { regex: "/consulting-page/launchHardcoverBook.png/" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, breakpoints: [210, 630])
      }
    }

    rails: file(relativePath: { regex: "/consulting-page/rails.png/" }) {
      childImageSharp {
        gatsbyImageData(layout: FIXED, height: 70)
      }
    }
    react: file(relativePath: { regex: "/consulting-page/react.png/" }) {
      childImageSharp {
        gatsbyImageData(layout: FIXED, height: 70)
      }
    }

    node: file(relativePath: { regex: "/consulting-page/node.png/" }) {
      childImageSharp {
        gatsbyImageData(layout: FIXED, height: 70)
      }
    }

    ios: file(relativePath: { regex: "/consulting-page/ios.png/" }) {
      childImageSharp {
        gatsbyImageData(layout: FIXED, height: 70)
      }
    }

    android: file(relativePath: { regex: "/consulting-page/android.png/" }) {
      childImageSharp {
        gatsbyImageData(layout: FIXED, height: 70)
      }
    }

    aws: file(relativePath: { regex: "/consulting-page/aws.png/" }) {
      childImageSharp {
        gatsbyImageData(layout: FIXED, height: 70)
      }
    }

    googleCloudPlatform: file(
      relativePath: { regex: "/consulting-page/googleCloudPlatform.png/" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FIXED, height: 70)
      }
    }

    docker: file(relativePath: { regex: "/consulting-page/docker.png/" }) {
      childImageSharp {
        gatsbyImageData(layout: FIXED, height: 70)
      }
    }

    heroku: file(relativePath: { regex: "/consulting-page/heroku.png/" }) {
      childImageSharp {
        gatsbyImageData(layout: FIXED, height: 70)
      }
    }
  }
`;
