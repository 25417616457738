import React, { useEffect, createRef } from "react";
import { useInView } from "react-intersection-observer";
import lottie from "lottie-web";

export const Lottie = ({ data, className = "", animateWhenInView = true }) => {
  let animationContainer = createRef();
  const { ref: inViewRef, inView } = useInView({
    delay: 1000,
    skip: !animateWhenInView,
    initialInView: false,
  });
  let anim;

  useEffect(() => {
    anim = lottie.loadAnimation({
      container: animationContainer.current,
      renderer: "svg",
      loop: false,
      autoplay: false,
      animationData: data,
      name: "growth",
    });
    return () => anim.destroy(); // optional clean up for unmounting
  }, [animationContainer]);

  const animate = () => {
    if (anim && anim.isPaused) {
      anim.setDirection(1);
      anim.goToAndPlay(0);
    }
  };

  useEffect(() => {
    if (inView) {
      animate();
    }
  }, [anim, inView, animate]);

  return (
    <div ref={inViewRef}>
      <div
        className={`lottie-container ${className}`}
        onMouseEnter={() => {
          animate();
        }}
        ref={animationContainer}
      />
    </div>
  );
};
